import React, { Component } from 'react';

export default class FunduLogo extends Component {
  render () {
    return (
      <svg
        width='130'
        height='38'
        viewBox='0 0 130 38'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M50.7702 37.0815C48.1819 37.0815 45.636 37.0815 43.0688 37.0815C43.0688 28.3946 43.0688 19.7236 43.0688 11.0208C45.6201 11.0208 48.174 11.0208 50.7649 11.0208C50.7649 12.666 50.7649 14.3138 50.7649 16.0359C51.0934 15.4822 51.3557 14.9894 51.6657 14.5284C53.4009 11.9295 55.8356 10.5042 58.9432 10.2155C60.5857 10.0644 62.207 10.1678 63.7966 10.6261C66.4193 11.3864 68.1361 13.0925 69.0713 15.6385C69.7203 17.4108 69.8899 19.2468 69.8978 21.1065C69.9137 26.3388 69.9031 31.5684 69.9031 36.8007C69.9031 36.8855 69.8952 36.9729 69.8899 37.0789C67.3174 37.0789 64.7636 37.0789 62.1647 37.0789C62.1647 36.9385 62.1647 36.8113 62.1647 36.6841C62.1647 31.9287 62.1779 27.1707 62.1514 22.4153C62.1461 21.5384 62.0507 20.6509 61.8785 19.7925C61.5129 17.9592 60.2307 16.7935 58.3842 16.5127C57.3907 16.3617 56.3999 16.3829 55.425 16.6081C53.6235 17.024 52.4128 18.1447 51.6816 19.8163C51.0325 21.2999 50.8073 22.8709 50.7941 24.4658C50.7623 28.5165 50.7755 32.5672 50.7729 36.6179C50.7702 36.7583 50.7702 36.9014 50.7702 37.0815Z'
          fill='#112530'
        />
        <path
          d='M93.7968 0.0185794C93.7968 4.76339 93.7968 10.0328 93.7968 14.8544C93.6696 14.6663 93.6034 14.5683 93.5371 14.4676C92.6073 13.0953 91.4177 12.0091 89.9183 11.2991C87.118 9.97182 84.1906 9.87115 81.2128 10.5149C78.8417 11.0262 76.8839 12.2502 75.3686 14.147C74.1764 15.6386 73.4346 17.3553 72.9922 19.1992C72.4411 21.4934 72.3405 23.8221 72.5577 26.1693C72.7511 28.2782 73.2439 30.2995 74.2373 32.1832C75.6017 34.7688 77.6655 36.5279 80.4737 37.3704C82.7494 38.0512 85.0648 38.1095 87.3856 37.6433C89.8653 37.1478 91.9079 35.9345 93.3994 33.8601C93.5186 33.6959 93.6272 33.5263 93.7438 33.3568C93.7676 33.3621 93.7941 33.37 93.818 33.3753C93.818 34.6099 93.818 35.8471 93.818 37.0763C96.4142 37.0763 98.9628 37.0763 101.527 37.0763C101.527 24.8951 101.527 12.1813 101.527 0.0159302C98.9522 0.0185794 96.3957 0.0185794 93.7968 0.0185794ZM93.8127 24.352C93.8127 29.0491 91.0654 31.9818 87.1154 31.9818C82.4951 31.9818 80.3518 28.7868 80.3518 24.0261C80.3518 19.329 82.4951 16.134 87.1154 16.134C91.068 16.134 93.8127 19.0694 93.8127 23.7002V24.352Z'
          fill='#112530'
        />
        <path
          d='M129.996 11.2752C129.996 11.1984 129.996 11.1242 129.996 11.0182H122.258V12.2793C122.255 17.1327 122.244 21.8219 122.244 26.527C122.244 28.1033 122.04 29.0332 121.606 29.9445C121.105 30.9989 119.841 32.2626 117.229 32.2705H117.166C114.554 32.2626 113.29 30.9989 112.789 29.9445C112.355 29.0305 112.151 28.1006 112.151 26.527C112.151 21.8219 112.137 17.1327 112.137 12.2793V11.0209H104.399C104.399 11.1242 104.399 11.201 104.399 11.2779C104.399 16.5207 104.388 20.235 104.404 25.4779C104.41 27.645 104.55 30.4134 105.52 32.3951C106.468 34.3317 107.965 35.6272 109.886 36.61C111.997 37.6909 114.305 37.932 116.933 37.9558H117.494C120.109 37.9294 122.409 37.6883 124.512 36.61C126.433 35.6272 127.93 34.3343 128.878 32.3951C129.848 30.4134 129.988 27.645 129.993 25.4779C130.007 20.235 129.996 16.5181 129.996 11.2752Z'
          fill='#112530'
        />
        <path
          d='M40.2051 11.2752C40.2051 11.1984 40.2051 11.1242 40.2051 11.0182H32.4666V12.2793C32.4639 17.1327 32.4533 21.8219 32.4533 26.527C32.4533 28.1033 32.2493 29.0332 31.8149 29.9445C31.3142 30.9989 30.0505 32.2626 27.4383 32.2705H27.3747C24.7626 32.2626 23.4989 30.9989 22.9982 29.9445C22.5637 29.0305 22.3597 28.1006 22.3597 26.527C22.3597 21.8219 22.3464 17.1327 22.3464 12.2793V11.0209H14.7961H7.72257L7.72522 8.22593C7.71462 6.76884 8.92798 5.58462 10.4116 5.56608C10.4116 5.56608 18.8839 5.55283 19.4482 5.55283H19.4508C20.6191 5.55283 21.6391 5.55283 22.3438 5.55283V0C22.3411 0 22.3358 0 22.3332 0C20.1794 0 12.5919 0 10.4407 0C2.37903 0 0.103321 3.8838 0.103321 8.75313C0.103321 10.1042 0 30.3339 0 37.0763C2.59097 37.0763 5.14485 37.0763 7.73052 37.0763C7.73052 33.1527 7.73052 29.2318 7.73052 25.3136C7.71992 25.3136 7.71197 25.3136 7.70138 25.3136C7.70138 23.6419 7.69873 20.19 7.70403 16.9234H14.6027C14.6 19.668 14.6 22.2935 14.6106 25.4805C14.6159 27.6476 14.7563 30.4161 15.726 32.3977C16.6744 34.3343 18.1712 35.6298 20.0919 36.6127C22.2034 37.6936 24.5109 37.9346 27.1389 37.9585H27.7006C30.3154 37.932 32.6149 37.6909 34.7185 36.6127C36.6392 35.6298 38.136 34.337 39.0844 32.3977C40.054 30.4161 40.1945 27.6476 40.1998 25.4805C40.2157 20.235 40.2051 16.5181 40.2051 11.2752Z'
          fill='#112530'
        />
      </svg>
    );
  }
}
