import { lazy } from 'react';

export default [
  {
    path: '/',
    component: lazy(() => import('components/views/start/Start')),
    title: 'Start',
    exact: true
  },
  {
    path: '/phone',
    component: lazy(() => import('components/views/phone/Phone')),
    title: 'Phone',
    exact: true
  },
  {
    path: '/results',
    component: lazy(() => import('components/views/results/Results')),
    title: 'Results',
    exact: true
  },
  {
    path: '/benefits',
    component: lazy(() => import('components/views/benefits/Benefits')),
    title: 'Benefits',
    exact: true
  },
  {
    path: '/contact',
    component: lazy(() => import('components/views/contact/Contact')),
    title: 'Contact',
    exact: true
  },
  {
    path: '/info',
    component: lazy(() => import('components/views/info/Info')),
    title: 'Info',
    exact: true
  }
];
