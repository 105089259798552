import React, { Component } from 'react';

export default class Varma extends Component {
  render () {
    return (
      <svg
        version='1.0'
        className='varma'
        xmlns='http://www.w3.org/2000/svg'
        width='946.000000pt'
        height='207.000000pt'
        viewBox='0 0 946.000000 207.000000'
        preserveAspectRatio='xMidYMid meet'
      >
        <g
          transform='translate(0.000000,207.000000) scale(0.100000,-0.100000)'
          fill='#000000'
          stroke='none'
        >
          <path
            d='M1030 1953 c-1 -368 -65 -831 -165 -1178 -43 -152 -137 -410 -159
-441 -11 -14 -20 2 -63 112 -164 416 -259 924 -266 1411 l-2 193 -187 0 -188
0 0 -37 c1 -95 22 -400 35 -508 66 -526 217 -1025 415 -1372 l67 -118 181 -3
181 -2 56 95 c242 410 412 1025 455 1645 5 80 10 182 10 228 l0 82 -185 0
-185 0 0 -107z'
          />
          <path
            d='M2175 1965 c-198 -336 -359 -856 -425 -1368 -17 -137 -39 -438 -40
-549 l0 -38 188 0 187 0 3 168 c2 92 7 203 10 247 l7 80 306 3 306 2 6 -52 c4
-29 10 -142 13 -251 l7 -197 185 0 185 0 -7 172 c-26 683 -201 1355 -466 1793
l-51 85 -179 0 -179 0 -56 -95z m289 -333 c54 -133 103 -290 145 -457 28 -114
71 -326 71 -352 0 -10 -61 -13 -270 -13 l-271 0 7 38 c21 126 81 382 114 493
58 195 144 419 155 408 2 -3 24 -55 49 -117z'
          />
          <path
            d='M3570 1036 l0 -1026 170 0 170 0 0 855 0 855 123 0 c87 0 137 -5 171
-16 68 -23 148 -101 188 -182 31 -63 33 -72 33 -177 0 -98 -3 -116 -26 -165
-50 -107 -132 -177 -243 -208 l-66 -18 232 -472 232 -472 174 0 c135 0 173 3
169 13 -3 6 -89 184 -192 393 l-187 381 49 62 c57 70 122 196 152 294 82 269
23 547 -153 722 -77 76 -151 120 -261 156 -66 21 -89 23 -402 27 l-333 4 0
-1026z'
          />
          <path
            d='M5672 1993 c-170 -288 -306 -671 -391 -1098 -42 -212 -60 -385 -68
-652 l-6 -233 186 0 187 0 0 248 c0 397 51 727 176 1132 37 117 120 339 133
353 17 18 134 -308 190 -529 86 -343 121 -594 128 -942 l6 -262 182 0 182 0 6
203 c14 436 78 825 198 1194 44 135 115 323 128 336 17 17 128 -288 185 -506
89 -340 117 -548 123 -924 l5 -303 186 0 185 0 -6 238 c-7 275 -19 404 -53
592 -80 436 -223 844 -404 1149 l-42 71 -179 0 -178 0 -27 -46 c-95 -162 -228
-573 -295 -909 l-12 -60 -18 106 c-49 289 -180 687 -283 864 l-27 45 -178 0
-179 0 -40 -67z'
          />
          <path
            d='M8523 1973 c-268 -457 -462 -1223 -463 -1825 l0 -138 183 0 184 0 6
213 c4 116 9 229 13 249 l7 38 302 0 c276 0 303 -2 309 -17 4 -9 10 -122 13
-250 l6 -233 184 0 183 0 0 128 c0 413 -97 948 -245 1355 -71 196 -188 442
-257 540 l-19 27 -177 0 -178 0 -51 -87z m309 -402 c75 -199 188 -641 188
-734 l0 -27 -270 0 -269 0 5 33 c55 300 142 611 234 831 l33 79 20 -39 c11
-21 37 -85 59 -143z'
          />
        </g>
      </svg>
    );
  }
}
