import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './TextBox.scss';

export default class TextBox extends Component {
  constructor (props) {
    super(props);
    this.textInput = React.createRef();
    this.focus = this.focus.bind(this);
  }

  componentDidMount () {
    if (this.props.isFocus) {
      this.focus();
    }
  }

  focus () {
    this.textInput.current.focus();
  }

  render () {
    const { props } = this;
    return (
      <div className='textbox-container'>
        <label>{props.label}</label>
        {props.errors[props.name].length > 0 && <span className='error'>{props.errors[props.name]}</span>}
        <input type={props.type} maxLength='50' value={props.value} name={props.name} onChange={props.onHandleChange} ref={this.textInput} />
      </div>
    );
  }
}

TextBox.propTypes = {
  label: PropTypes.string,
  errors: PropTypes.object,
  name: PropTypes.string,
  value: PropTypes.string,
  type: PropTypes.string,
  onHandleChange: PropTypes.func,
  isFocus: PropTypes.bool
};
