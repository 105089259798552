import React, { Component } from 'react';

export default class Saastopanki extends Component {
  render () {
    return (
      <svg
        width='119'
        height='21'
        viewBox='0 0 119 21'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M9.30544 13.3385C9.30544 11.8439 8.84298 10.4579 8.02456 9.33261H5.34857C6.59722 10.2225 7.41424 11.6863 7.41424 13.3385C7.41424 15.0027 6.59162 16.4728 5.33105 17.3571H13.2987C12.0396 16.4728 11.2149 15.0027 11.2149 13.3385C11.2149 11.6863 12.0319 10.2225 13.2826 9.33261H10.6045C9.78823 10.4579 9.32366 11.8439 9.32366 13.3385H9.30544Z'
          fill='#04B5D6'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M9.31164 2.31728C8.62495 2.31728 7.9775 2.58495 7.49191 3.07474C7.00423 3.56313 6.73305 4.21058 6.73305 4.90358C6.73305 5.56644 6.85918 5.97495 7.00142 6.38136C7.14156 6.78707 7.2796 7.1192 7.41484 7.43101C7.10583 7.29367 6.7744 7.15634 6.36939 7.0162C5.96298 6.87395 5.55377 6.74853 4.89511 6.74853C4.20702 6.74853 3.55677 7.0162 3.06978 7.50669C2.58349 7.99228 2.31582 8.64253 2.31582 9.33342C2.31582 10.0236 2.58489 10.6739 3.07118 11.1609C3.55817 11.6499 4.20842 11.9211 4.89651 11.9211C5.2882 11.9211 5.6848 11.8461 6.0807 11.6324C6.33225 12.172 6.46258 12.7304 6.46258 13.3393C6.46258 13.5636 6.44226 13.7787 6.40653 13.991C5.90272 14.1536 5.39331 14.2369 4.89651 14.2369C3.5883 14.2369 2.36067 13.7261 1.43434 12.797C0.511513 11.8735 0 10.6423 0 9.33342C0 6.79127 2.01452 4.55883 4.51112 4.5224C4.54756 2.01808 6.7772 5.91278e-05 9.31164 5.91278e-05C11.8489 5.91278e-05 14.0799 2.01808 14.1143 4.5224C16.6144 4.55883 18.6247 6.79127 18.6247 9.33342C18.6247 10.6409 18.116 11.8735 17.1917 12.797C16.2668 13.7261 15.0364 14.2369 13.7296 14.2369C13.2342 14.2369 12.7248 14.1536 12.221 13.991C12.1859 13.7787 12.1642 13.5636 12.1642 13.3393C12.1642 12.7304 12.2924 12.172 12.5454 11.6324C12.9399 11.8461 13.3365 11.9211 13.7296 11.9211C14.4191 11.9211 15.0665 11.6499 15.5535 11.1609C16.0412 10.6739 16.3103 10.0236 16.3103 9.33342C16.3103 8.64253 16.0412 7.99228 15.5549 7.50669C15.0679 7.0162 14.4205 6.74853 13.7324 6.74853C13.0709 6.74853 12.6631 6.87395 12.2553 7.0162C11.8531 7.15634 11.5203 7.29367 11.2112 7.43101C11.3451 7.1192 11.4852 6.78707 11.6247 6.38136C11.7655 5.97495 11.8916 5.56644 11.8916 4.90358C11.8916 4.21058 11.6219 3.56313 11.1363 3.07474C10.6486 2.58495 10.0011 2.31728 9.31164 2.31728Z'
          fill='#04B5D6'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M25.1772 17.5148C23.3764 17.5148 21.823 16.7377 20.7817 15.5192L22.1054 14.2313C22.935 15.1317 24.1528 15.6957 25.3181 15.6957C27.0488 15.6957 27.8785 15.0966 27.8785 13.8963C27.8785 12.9427 27.1539 12.4662 25.089 11.8482C22.476 11.0718 21.2232 10.4188 21.2232 8.21224C21.2232 6.0765 23.0233 4.76969 25.3006 4.76969C26.9774 4.76969 28.2484 5.38771 29.3429 6.44647L28.0368 7.7708C27.2604 6.9762 26.3776 6.58801 25.1597 6.58801C23.7121 6.58801 23.0941 7.29432 23.0941 8.12395C23.0941 8.98862 23.6588 9.41254 25.7946 10.0481C28.2309 10.7887 29.7493 11.5658 29.7493 13.7723C29.7493 15.9963 28.3367 17.5148 25.1772 17.5148Z'
          fill='#00B5D1'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M36.7217 17.3207V16.4021C36.0861 17.0383 35.1857 17.5148 34.1795 17.5148C32.6793 17.5148 30.9668 16.6676 30.9668 14.3903C30.9668 12.3254 32.556 11.3892 34.656 11.3892C35.5214 11.3892 36.2102 11.5133 36.7217 11.7424V11.0543C36.7217 10.0481 36.1036 9.48261 34.9741 9.48261C34.0212 9.48261 33.2798 9.65989 32.556 10.0656L31.8497 8.68872C32.7326 8.14147 33.7206 7.85909 35.0267 7.85909C37.0923 7.85909 38.5043 8.86529 38.5043 10.966V17.3207H36.7217ZM33.2616 6.90613C32.6085 6.90613 32.0613 6.35818 32.0613 5.72334C32.0613 5.06959 32.6085 4.52304 33.2616 4.52304C33.8971 4.52304 34.4444 5.06959 34.4444 5.72334C34.4444 6.35818 33.8971 6.90613 33.2616 6.90613ZM36.7217 13.3666C36.227 13.1193 35.5921 12.9602 34.6034 12.9602C33.4382 12.9602 32.6968 13.4899 32.6968 14.3371C32.6968 15.2557 33.2798 15.873 34.4801 15.873C35.4506 15.873 36.2984 15.2732 36.7217 14.6902V13.3666ZM36.7042 6.90613C36.0511 6.90613 35.5038 6.35818 35.5038 5.72334C35.5038 5.06959 36.0511 4.52304 36.7042 4.52304C37.3397 4.52304 37.8869 5.06959 37.8869 5.72334C37.8869 6.35818 37.3397 6.90613 36.7042 6.90613Z'
          fill='#00B5D1'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M45.5854 17.3207V16.4021C44.9499 17.0383 44.0495 17.5148 43.0433 17.5148C41.5431 17.5148 39.8306 16.6676 39.8306 14.3903C39.8306 12.3254 41.4198 11.3892 43.5205 11.3892C44.3851 11.3892 45.0739 11.5133 45.5854 11.7424V11.0543C45.5854 10.0481 44.9674 9.48261 43.8379 9.48261C42.8849 9.48261 42.1436 9.65989 41.4198 10.0656L40.7135 8.68872C41.5963 8.14147 42.5843 7.85909 43.8911 7.85909C45.9561 7.85909 47.3687 8.86529 47.3687 10.966V17.3207H45.5854ZM42.1254 6.90613C41.473 6.90613 40.9251 6.35818 40.9251 5.72334C40.9251 5.06959 41.473 4.52304 42.1254 4.52304C42.7609 4.52304 43.3082 5.06959 43.3082 5.72334C43.3082 6.35818 42.7609 6.90613 42.1254 6.90613ZM45.5854 13.3666C45.0914 13.1193 44.4559 12.9602 43.4672 12.9602C42.3019 12.9602 41.5606 13.4899 41.5606 14.3371C41.5606 15.2557 42.1436 15.873 43.3439 15.873C44.3144 15.873 45.1622 15.2732 45.5854 14.6902V13.3666ZM45.5679 6.90613C44.9149 6.90613 44.3676 6.35818 44.3676 5.72334C44.3676 5.06959 44.9149 4.52304 45.5679 4.52304C46.2035 4.52304 46.7507 5.06959 46.7507 5.72334C46.7507 6.35818 46.2035 6.90613 45.5679 6.90613Z'
          fill='#00B5D1'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M52.1358 17.5148C50.8822 17.5148 49.5235 17.0208 48.5874 16.332L49.417 14.9551C50.2824 15.5549 51.2529 15.8723 52.1175 15.8723C53.2653 15.8723 53.8125 15.5024 53.8125 14.8486C53.8125 14.143 53.0004 13.8074 51.6593 13.2958C49.6644 12.5188 48.9406 11.8482 48.9406 10.383C48.9406 8.72375 50.2474 7.85909 52.1007 7.85909C53.2653 7.85909 54.413 8.28231 55.2427 8.88281L54.4481 10.2772C53.6892 9.74748 52.9304 9.48261 52.0475 9.48261C51.1646 9.48261 50.7764 9.83577 50.7764 10.3298C50.7764 10.7887 51.0945 11.1251 52.4889 11.6716C54.5539 12.4662 55.6133 13.0317 55.6133 14.7785C55.6133 16.8267 53.9541 17.5148 52.1358 17.5148Z'
          fill='#00B5D1'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M61.5249 17.0915C61.0834 17.3382 60.4661 17.5148 59.7423 17.5148C58.4537 17.5148 57.6591 16.7209 57.6591 15.0609V9.71315H56.3354V8.05248H57.6591V5.40522L59.4417 4.45227V8.05248H61.7365V9.71315H59.4417V14.7435C59.4417 15.6089 59.7247 15.8555 60.3953 15.8555C60.8718 15.8555 61.4015 15.6789 61.7365 15.4848L61.5249 17.0915Z'
          fill='#00B5D1'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M66.6448 17.5148C64.3149 17.5148 62.5674 15.5906 62.5674 12.6953C62.5674 9.65919 64.3682 7.85909 66.698 7.85909C69.0636 7.85909 70.8287 9.74748 70.8287 12.6428C70.8287 15.714 69.0279 17.5148 66.6448 17.5148ZM64.933 6.99372C64.2799 6.99372 63.7327 6.44647 63.7327 5.81093C63.7327 5.15788 64.2799 4.61063 64.933 4.61063C65.5678 4.61063 66.1157 5.15788 66.1157 5.81093C66.1157 6.44647 65.5678 6.99372 64.933 6.99372ZM66.6273 9.58912C65.2504 9.58912 64.4032 10.8069 64.4032 12.6428C64.4032 14.672 65.3387 15.7847 66.7155 15.7847C68.0924 15.7847 68.9928 14.3903 68.9928 12.6953C68.9928 10.8069 68.0924 9.58912 66.6273 9.58912ZM68.3748 6.99372C67.7218 6.99372 67.1745 6.44647 67.1745 5.81093C67.1745 5.15788 67.7218 4.61063 68.3748 4.61063C69.0103 4.61063 69.5576 5.15788 69.5576 5.81093C69.5576 6.44647 69.0103 6.99372 68.3748 6.99372Z'
          fill='#00B5D1'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M76.3557 17.5147C75.4203 17.5147 74.5374 17.0207 74.0259 16.4552V19.792L72.2251 20.7274V8.0524H74.0259V8.93529C74.6965 8.31797 75.4729 7.85901 76.444 7.85901C78.4389 7.85901 80.0099 9.30596 80.0099 12.8368C80.0099 16.0138 78.2799 17.5147 76.3557 17.5147ZM76.1084 9.53579C75.2437 9.53579 74.4842 10.1181 74.0077 10.7186V14.6194C74.4141 15.1666 75.2612 15.8372 76.1792 15.8372C77.4853 15.8372 78.1916 14.8485 78.1916 12.8901C78.1916 10.5595 77.4502 9.53579 76.1084 9.53579Z'
          fill='#00B5D1'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M86.7539 17.3207V16.4021C86.1184 17.0383 85.218 17.5148 84.2117 17.5148C82.7115 17.5148 80.999 16.6676 80.999 14.3903C80.999 12.3254 82.5882 11.3892 84.6882 11.3892C85.5536 11.3892 86.2424 11.5133 86.7539 11.7424V11.0543C86.7539 10.0481 86.1359 9.48261 85.0063 9.48261C84.0527 9.48261 83.3113 9.65989 82.5882 10.0656L81.8819 8.68872C82.7648 8.14147 83.7528 7.85909 85.0589 7.85909C87.1246 7.85909 88.5365 8.86529 88.5365 10.966V17.3207H86.7539ZM86.7539 13.3666C86.2592 13.1193 85.6244 12.9602 84.6357 12.9602C83.4704 12.9602 82.7291 13.4899 82.7291 14.3371C82.7291 15.2557 83.3113 15.873 84.5116 15.873C85.4828 15.873 86.33 15.2732 86.7539 14.6902V13.3666Z'
          fill='#00B5D1'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M95.9341 17.3207V12.0773C95.9341 10.2064 95.2278 9.53657 94.0275 9.53657C92.8096 9.53657 92.1208 10.4012 92.1208 11.9014V17.3207H90.3032V8.05248H92.1208V8.93537C92.5798 8.2648 93.4984 7.85909 94.4514 7.85909C96.6046 7.85909 97.7524 9.271 97.7524 12.0072V17.3207H95.9341Z'
          fill='#00B5D1'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M104.797 17.3207L102.749 12.7136L101.337 14.5851V17.3207H99.5186V5.40523L101.337 4.45227V10.2954C101.337 10.8595 101.319 11.8139 101.319 12.2721C101.566 11.9014 102.025 11.1951 102.343 10.7544L104.267 8.05248H106.332L103.985 11.1601L106.774 17.3207H104.797Z'
          fill='#00B5D1'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M113.287 17.3207L111.24 12.7136L109.827 14.5851V17.3207H108.009V5.40523L109.827 4.45227V10.2954C109.827 10.8595 109.81 11.8139 109.81 12.2721C110.057 11.9014 110.516 11.1951 110.833 10.7544L112.757 8.05248H114.822L112.475 11.1601L115.264 17.3207H113.287Z'
          fill='#00B5D1'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M117.508 6.85291C116.855 6.85291 116.325 6.32318 116.325 5.68834C116.325 5.03529 116.855 4.50556 117.508 4.50556C118.144 4.50556 118.673 5.03529 118.673 5.68834C118.673 6.32318 118.144 6.85291 117.508 6.85291ZM118.408 17.3214H116.589V8.05322H118.408V17.3214Z'
          fill='#00B5D1'
        />
      </svg>
    );
  }
}
