import React, { Component, Suspense } from 'react';
import { connect } from 'react-redux';
import { Switch } from 'react-router-dom';

import routeList, { routes } from '../../routes';

import FeedbackDrawer from 'components/general/feedbackDrawer/FeedbackDrawer';
import Main from 'components/layouts/Main';
import Loader from 'components/utils/loader/Loader';

import './App.scss';

/**
 * App component
 */
class App extends Component {
  componentDidMount () {
    const getParamValue = function (paramName) {
      const url = window.location.href.substring(window.location.href.indexOf('?') + 1);
      const qArray = url.split('&');
      for (let i = 0; i < qArray.length; i++) {
        const pArr = qArray[i].split('=');
        if (pArr[0] === paramName) {
          return pArr[1];
        }
      }
    };

    if (getParamValue('main-text-color')) {
      document.documentElement.style.setProperty('--main-text-color', getParamValue('main-text-color'));
    }
    if (getParamValue('main-secondary-color')) {
      document.documentElement.style.setProperty('--main-secondary-color', getParamValue('main-secondary-color'));
    }
    if (getParamValue('main-input-label-color')) {
      document.documentElement.style.setProperty('--main-input-label-color', getParamValue('main-input-label-color'));
    }
    if (getParamValue('main-background-color')) {
      document.documentElement.style.setProperty('--main-background-color', getParamValue('main-background-color'));
    }
    if (getParamValue('main-accent-color')) {
      document.documentElement.style.setProperty('--main-accent-color', getParamValue('main-accent-color'));
    }
    if (getParamValue('main-accent-color-2')) {
      document.documentElement.style.setProperty('--main-accent-color-2', getParamValue('main-accent-color-2'));
    }
    if (getParamValue('main-accent-text-color')) {
      document.documentElement.style.setProperty('--main-accent-text-color', getParamValue('main-accent-text-color'));
    }
    if (getParamValue('alternate-text-color')) {
      document.documentElement.style.setProperty('--alternate-text-color', getParamValue('alternate-text-color'));
    }
    if (getParamValue('alternate-background-color')) {
      document.documentElement.style.setProperty('--alternate-background-color', getParamValue('alternate-background-color'));
    }
  }

  render () {
    return (
      <div>
        <div className='App' handleclick={this.onClick}>
          <Main>
            <Suspense fallback={<Loader />}>
              <Switch>
                {routes(routeList)}
              </Switch>
            </Suspense>
          </Main>
        </div>
        <FeedbackDrawer />
      </div>
    );
  }
}

export default connect()(App);
